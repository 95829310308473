exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aviso-de-privacidad-tsx": () => import("./../../../src/pages/aviso-de-privacidad.tsx" /* webpackChunkName: "component---src-pages-aviso-de-privacidad-tsx" */),
  "component---src-pages-danzadelpocho-tsx": () => import("./../../../src/pages/danzadelpocho.tsx" /* webpackChunkName: "component---src-pages-danzadelpocho-tsx" */),
  "component---src-pages-eventos-index-tsx": () => import("./../../../src/pages/eventos/index.tsx" /* webpackChunkName: "component---src-pages-eventos-index-tsx" */),
  "component---src-pages-eventos-registrar-tsx": () => import("./../../../src/pages/eventos/registrar.tsx" /* webpackChunkName: "component---src-pages-eventos-registrar-tsx" */),
  "component---src-pages-experiencias-tsx": () => import("./../../../src/pages/experiencias.tsx" /* webpackChunkName: "component---src-pages-experiencias-tsx" */),
  "component---src-pages-explora-tabasco-aguaselva-tsx": () => import("./../../../src/pages/explora-tabasco/aguaselva.tsx" /* webpackChunkName: "component---src-pages-explora-tabasco-aguaselva-tsx" */),
  "component---src-pages-explora-tabasco-atractivos-tsx": () => import("./../../../src/pages/explora-tabasco/atractivos.tsx" /* webpackChunkName: "component---src-pages-explora-tabasco-atractivos-tsx" */),
  "component---src-pages-explora-tabasco-frontera-tsx": () => import("./../../../src/pages/explora-tabasco/frontera.tsx" /* webpackChunkName: "component---src-pages-explora-tabasco-frontera-tsx" */),
  "component---src-pages-explora-tabasco-tapijulapa-tsx": () => import("./../../../src/pages/explora-tabasco/tapijulapa.tsx" /* webpackChunkName: "component---src-pages-explora-tabasco-tapijulapa-tsx" */),
  "component---src-pages-explora-tabasco-teapa-tsx": () => import("./../../../src/pages/explora-tabasco/teapa.tsx" /* webpackChunkName: "component---src-pages-explora-tabasco-teapa-tsx" */),
  "component---src-pages-explora-tabasco-zona-luz-tsx": () => import("./../../../src/pages/explora-tabasco/zona-luz.tsx" /* webpackChunkName: "component---src-pages-explora-tabasco-zona-luz-tsx" */),
  "component---src-pages-hoteles-tsx": () => import("./../../../src/pages/hoteles.tsx" /* webpackChunkName: "component---src-pages-hoteles-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-laferiatabasco-tsx": () => import("./../../../src/pages/laferiatabasco.tsx" /* webpackChunkName: "component---src-pages-laferiatabasco-tsx" */),
  "component---src-pages-politica-de-cookies-tsx": () => import("./../../../src/pages/politica-de-cookies.tsx" /* webpackChunkName: "component---src-pages-politica-de-cookies-tsx" */),
  "component---src-pages-restaurantes-tsx": () => import("./../../../src/pages/restaurantes.tsx" /* webpackChunkName: "component---src-pages-restaurantes-tsx" */),
  "component---src-pages-tabasco-llega-a-italia-tsx": () => import("./../../../src/pages/tabasco-llega-a-italia.tsx" /* webpackChunkName: "component---src-pages-tabasco-llega-a-italia-tsx" */),
  "component---src-pages-terminos-y-condiciones-tsx": () => import("./../../../src/pages/terminos-y-condiciones.tsx" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-tsx" */),
  "component---src-templates-atractivo-tsx": () => import("./../../../src/templates/atractivo.tsx" /* webpackChunkName: "component---src-templates-atractivo-tsx" */),
  "component---src-templates-experiencia-tsx": () => import("./../../../src/templates/experiencia.tsx" /* webpackChunkName: "component---src-templates-experiencia-tsx" */),
  "component---src-templates-hotel-tsx": () => import("./../../../src/templates/hotel.tsx" /* webpackChunkName: "component---src-templates-hotel-tsx" */),
  "component---src-templates-restaurante-tsx": () => import("./../../../src/templates/restaurante.tsx" /* webpackChunkName: "component---src-templates-restaurante-tsx" */),
  "component---src-templates-ruta-index-tsx": () => import("./../../../src/templates/ruta/index.tsx" /* webpackChunkName: "component---src-templates-ruta-index-tsx" */)
}

